<template>
    <div class="text-center pt-2.5">
        <h3>Adam Stubbs</h3>
        <h6>Voiceover Artist</h6>
    </div>
    <div class="flex-none lg:flex justify-evenly py-8">
        <div class="py-5 px-2.5">
            <img class="rounded-full max-h-96 max-w-96 m-auto" src="../../assets/img/profile.png" alt="Profile Photo">
        </div>
        <Card>
            <template #title>Hello!</template>
            <template #content>
                <p>I'm Adam Stubbs, a British Voiceover artist working from my home studio on The Wirral.</p>
                <p>I began narrating audiobooks for Audible, bringing stories to life with my natural story teller tone.</p>
                <p>Now I can harness my natural RP accent with a touch of my Wirral (born and bred) twang to bring you the read that you need.</p>
            </template>
        </Card>
    </div>
</template>
