<template>
    <div class="py-8 container mx-auto">
        <Card>
            <template #title>What I'm working on</template>
            <template #content>
                <p>This is where I will update with any current projects I'm working on.</p>
            </template>
        </Card>
    </div>
</template>
