<template>
    <div class="content px-2 sm:px-10 grow">
        <Banner />
        <router-view />
    </div>
</template>

<script>
import Banner from './Banner.vue'
import Home from './Home.vue'
import Demos from './Demos.vue'
import Audiobooks from './Audiobooks.vue'
import Current from './Current.vue'

export default {
  name: 'App',
  components: {
    Banner,
    Home,
    Demos,
    Audiobooks,
    Current
  }
}
</script>
