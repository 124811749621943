<template>
    <MedNavBar />
</template>

<script>
import MedNavBar from './partial/MedNavBar.vue'
export default {
    name: 'Header',
    components: {
        MedNavBar
    }
}
</script>
