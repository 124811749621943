<template>
    <div class="py-8 container mx-auto">
        <Card>
            <template #content>
                <TabView>
                    <TabPanel header="Commercial">
                        <DataView :value="commercials">
                            <template #list="slotProps">
                                <AudioPlayer :id="slotProps.data.id" :name="slotProps.data.name" :url="slotProps.data.path" />
                                <Divider type="solid" />
                            </template>
                        </DataView>
                    </TabPanel>
                    <TabPanel header="Corporate">
                        <DataView :value="corporates">
                            <template #list="slotProps">
                                <AudioPlayer :name="slotProps.data.name" :url="slotProps.data.path" />
                                <Divider type="solid" />
                            </template>
                        </DataView>
                    </TabPanel>
                </TabView>
            </template>
        </Card>
    </div>
</template>

<script>

import AudioPlayer from './partial/AudioPlayer.vue';

export default {
    components: {
        AudioPlayer
    },
    data() {
        return {
            commercials: [
                {
                    id: 1,
                    name: 'Commercial mix',
                    path: require('url:../../assets/aud/Adam-Comms-mix.mp3')
                },
                {
                    id: 2,
                    name: 'Commercial - City College',
                    path: require('url:../../assets/aud/Adam_Commercials_-_City_College.mp3')
                },
                {
                    id: 3,
                    name: 'Commercial - Sausages',
                    path: require('url:../../assets/aud/Adam_Commercials_-_Sausages.mp3')
                },
                {
                    id: 4,
                    name: 'Commercial - Shouting',
                    path: require('url:../../assets/aud/Adam_Commercials_-_Shouting.mp3')
                },
                {
                    id: 5,
                    name: 'Commercial - Speke Hall',
                    path: require('url:../../assets/aud/Adam_Commercials_-_Speke_Hall.mp3')
                },
                {
                    id: 6,
                    name: 'Commercial - UniBond',
                    path: require('url:../../assets/aud/Adam_Commercials_-_UniBond.mp3')
                }
            ],
            corporates: [
                {
                    id: 1,
                    name: 'Corporate mix',
                    path: require('url:../../assets/aud/Adam-Corp-Mix.mp3')
                },
                {
                    id: 2,
                    name: 'API Explainer',
                    path: require('url:../../assets/aud/Adam_API_explainer.mp3')
                },
                {
                    id: 3,
                    name: 'Branding',
                    path: require('url:../../assets/aud/Adam_Branding.mp3')
                },
                {
                    id: 4,
                    name: 'Course Intro',
                    path: require('url:../../assets/aud/Adam_Course_Intro.mp3')
                },
                {
                    id: 5,
                    name: 'Course Settings',
                    path: require('url:../../assets/aud/Adam_Course_Settings.mp3')
                },
                {
                    id: 6,
                    name: 'Dendrochronology',
                    path: require('url:../../assets/aud/Adam_Dendrochronology.mp3')
                },
                {
                    id: 7,
                    name: 'NOC Dry Example',
                    path: require('url:../../assets/aud/Adam_NOC_dry_example.mp3')
                }
            ]
        }
    }
}
</script>
