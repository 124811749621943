<template>
    <div class="py-8 container mx-auto">
        <Card>
            <template #title>Audiobooks</template>
            <template #content>
                I'm currently working on listing all my audiobooks here, but for now why not check them out on <a class="underline" href="https://www.audible.co.uk/search?keywords=&narrator=Adam+Stubbs">audible</a>?
            </template>
        </Card>
    </div>
</template>

<script>

import Book from './partial/Book.vue';

export default {
    components: {
        Book
    },
    data() {
        return {
            books: null
        }
    },
    created() {
       this.getBooks()
    },
    methods: {
        async getBooks() {
            this.books = [
                {
                    id: 1,
                    title: "Some Book",
                    subtitle: "Some Subtitle",
                    author: "Some Author",
                    url: "http://example.com",
                    series: "Some Series",
                    coverUrl: "https://m.media-amazon.com/images/I/5184Jy1-L3L._SL500_.jpg"
                },
                {
                    id: 2,
                    title: "Some Other Book With a Long Title",
                    subtitle: "Some Other Subtitle",
                    author: "Some Other Author",
                    url: "http://example.com",
                    series: "Some Other Series",
                    coverUrl: "https://m.media-amazon.com/images/I/5184Jy1-L3L._SL500_.jpg"
                },
                {
                    id: 3,
                    title: "Some Other Book With a Title to Rival That of an Isekai Novel",
                    subtitle: "Some Other Subtitle",
                    author: "Some Other Author",
                    url: "http://example.com",
                    series: "Some Other Series",
                    coverUrl: "https://m.media-amazon.com/images/I/5184Jy1-L3L._SL500_.jpg"
                },
                {
                    id: 4,
                    title: "Some Other Book",
                    subtitle: "Some Other Subtitle",
                    author: "Some Other Author",
                    url: "http://example.com",
                    series: "Some Other Series",
                    coverUrl: "https://m.media-amazon.com/images/I/5184Jy1-L3L._SL500_.jpg"
                },
                {
                    id: 5,
                    title: "Some Other Book",
                    subtitle: "Some Other Subtitle",
                    author: "Some Other Author",
                    url: "http://example.com",
                    series: "Some Other Series",
                    coverUrl: "https://m.media-amazon.com/images/I/5184Jy1-L3L._SL500_.jpg"
                },
            ];
        }
    }
}
</script>