<!-- Use this navbar when screen is just the wrong size -->
<template>
    <div class="hidden md:block md:text-center md:flex-1 md:grow smd:hidden">
        <div class="py-8">
            <router-link to="/" class="text-4xl px-2">HOME</router-link>
            <router-link to="/audiobooks" class="text-4xl px-2">AUDIOBOOKS</router-link>
            <router-link to="/demos" class="text-4xl px-2">DEMOS</router-link>
            <router-link to="/current" class="text-4xl px-2">CURRENT</router-link>
        </div>
    </div>
</template>