<template id="mobileNavbar">
    <div id= "hamburger-mobile" class="hamburger block py-2.5 pl-2.5 md:hidden">
        <a href="javascript:void(0);" v-on:click="toggleNav">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
            </svg>
        </a>
        <div id="menu-mobile" class="menu-expandable block py-4 pl-2.5 hidden">
            <router-link to="/" class="text-black block pb-4 pl-2.5">Home</router-link>
            <router-link to="/audiobooks" class="text-black block pb-4 pl-2.5">Audiobooks</router-link>
            <router-link to="/demos" class="text-black block pb-4 pl-2.5">Demos</router-link>
            <router-link to="/current" class="text-black block pb-4 pl-2.5">Current</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'mobileNavbar',
        template: '#mobileNavbar',
        methods: {
            toggleNav() {
                let x = document.getElementById("menu-mobile");
                let y = document.getElementById("hamburger-mobile");
                if (x.classList.contains('hidden')){
                    x.classList.remove('hidden');
                    x.classList.add('block');
                    y.style.backgroundColor = '#f6f6f2';
                } else {
                    x.classList.add('hidden');
                    x.classList.remove('block');
                    y.style.backgroundColor = '#badfe7';
                }
            }
        }
    }
</script>