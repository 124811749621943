<template>
    <div class="pb-0 text-right">
        <a class="text-sm md:text-base align-middle inline-block no-underline" :href="link" target="_blank">
            <!-- Fiverr logo is awkward so needs it's own component -->
            <g v-if="svg==='fiverr'">
                <FiverrLogo />
            </g>
            <g v-else>
                <svg class="align-middle inline-block pr-0.5 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :d="svg"/>
                </svg>
            </g>
            {{name}}
        </a>
    </div>
</template>

<script>
import FiverrLogo from '../icons/FiverrLogo.vue'

export default {
    components: {
        FiverrLogo: FiverrLogo
    },
    props: ['link', 'name', 'svg']
}

</script>
