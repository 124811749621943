<template>
  <div id="app" class="flex flex-col h-screen">
    <Header></Header>
    <MobileNavbar></MobileNavbar>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import MobileNavbar from './components/partial/MobileNavbar.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    MobileNavbar,
    Content,
    Footer
  }
}
</script>