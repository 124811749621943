<template>
    <div id="audio-player" class="pb-4 pt-2">
        <h6 class="pb-2">{{name}}</h6>

        <audio :id="id"
               ref="player"
               controls>
            <source :src="url" type="audio/mpeg">
        </audio>
    </div>
</template>

<script setup>

const props = defineProps(['id', 'name', 'url'])

</script>
