import '../assets/css/index.css'

import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
// import { createPinia } from 'pinia'

import PrimeVue from 'primevue/config'
import Tailwind from 'primevue/passthrough/tailwind'
import Button from 'primevue/button'
import Card from 'primevue/card'
import DataView from 'primevue/dataview'
import Divider from 'primevue/divider'
import Message from 'primevue/message'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'

const app = createApp(App);

app.component('Button', Button)
app.component('Card', Card)
app.component('DataView', DataView)
app.component('Divider', Divider)
app.component('Message', Message)
app.component('TabPanel', TabPanel)
app.component('TabView', TabView)

app.use(PrimeVue, { unstyled: true, pt: Tailwind })
app.use(router)
// app.use(createPinia())

app.mount('#app');
