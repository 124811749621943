import { createWebHistory, createRouter } from 'vue-router';

import Home from '../components/Home.vue';
import Audiobooks from '../components/Audiobooks.vue';
import Demos from '../components/Demos.vue';
import Current from '../components/Current.vue';
import NotFound from '../components/partial/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/demos',
    name: 'Demos',
    component: Demos,
  },
  {
    path: '/audiobooks',
    name: 'Audiobooks',
    component: Audiobooks,
  },
  {
    path: '/current',
    name: 'Current',
    component: Current,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
